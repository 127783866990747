import React from "react";
import { useStore } from "../lib/storeData";
import { FormattedMessage } from "react-intl";
import Flex from "../shared/globals/UiElements/Flex";
import { P } from "../shared/globals";
import { disableMainLayout } from "../shared/utils/layout";
import { MaintenanceModeIllustration } from "../assets/Icons";
import HTMLRender from "../shared/globals/UiElements/HTMLRender";
function MaintenanceMode() {
  const { maintenanceMode } = useStore();

  return (
    <Flex
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      column
      spacing="l"
    >
      <MaintenanceModeIllustration />
      <div
        style={{
          textAlign: "center",
        }}
      >
        {!maintenanceMode?.message ? (
          <>
            <P fontWeight="600" fontSize="1.2rem">
              <FormattedMessage
                defaultMessage="We’re currently updating our shop, 
and we will be back really soon"
              />
            </P>
            <FormattedMessage defaultMessage="Thanks for your patience" />
          </>
        ) : (
          <HTMLRender html={maintenanceMode?.message} />
        )}
      </div>
    </Flex>
  );
}

MaintenanceMode.useLayout = disableMainLayout;
export default MaintenanceMode;
